<template>
    <v-card flat class="fill-height box">
        <v-app-bar flat class="row header mx-0">
            <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
            <v-divider class="mx-3" inset vertical></v-divider>
            <span class="headline">Rental Shop</span>

        </v-app-bar>
        <v-card-text :class="$vuetify.breakpoint.smAndDown ? 'mx-0' : 'row content mx-0'">
        
            <v-row align-content="center" justify="center">
                <v-col cols="10" md="8" lg="6">
                    <div class="headline pl-0">Outfit</div>
                    <v-card-subtitle class="pt-0 pl-0">This is the outfit that has been chosen for you</v-card-subtitle>

                    <v-row>
                        <v-col cols="12">
                            <div class="notice" v-if="reservationFound === false">
                                You are not registered in this event. Please make sure that you are part of this event and the information entered in the previous step is accurate.
                            </div>

                            <div v-else>
                                <v-list>
                                    <v-list-item v-for="item in items" :key="item.itemId" class="px-0">
                                        <v-list-item-avatar size="56">
                                            <v-img :src="item.imgSrc"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.description }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.category }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>

                                <v-row no-gutters v-if="localEvent != null">
                                    <v-col cols="6">Subtotal:</v-col>
                                    <v-col cols="6" class="text-right">{{ toMoney( localEvent.reservations[0].subtotal ) }}</v-col>
                                    <v-col cols="6">
                                        Other charges / discounts: 
                                        <v-tooltip top>
                                            This includes a summarized amount of the damage waiver, discounts and extra charges.
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                                            </template>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="6" class="text-right">{{ toMoney( localEvent.reservations[0].otherChargesDiscount ) }}</v-col>
                                    <v-col cols="6">Tax (IVU):</v-col>
                                    <v-col cols="6" class="text-right">{{ toMoney( localEvent.reservations[0].tax ) }}</v-col>
                                    <v-col cols="6">Total:</v-col>
                                    <v-col cols="6" class="text-right">{{ toMoney( localEvent.reservations[0].total ) }}</v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            
                        </v-col>
                    </v-row>                

                </v-col>
            </v-row>

            <v-btn absolute bottom right depressed x-large color="primary" v-show="reservationFound === true" @click="nextStep">
                <v-icon left>mdi-arrow-right</v-icon> Next
            </v-btn>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { API } from '@/inc/api';
import { _st } from '@/softech';
import { mask } from 'vue-the-mask';

export default {
    data: () => ({
        localEvent  : null,
        loading     : false,
        isMobile    : false,
    }),
    computed: {
        ...mapGetters({
            event   : 'event',
            customer: 'customer'
        }),
        reservationFound() {
            if( this.localEvent == null )
                return null;

            let ix = this.localEvent.reservations.findIndex(i => i.customerId == this.customer.id);
            if( ix == -1 )
                return false;

            return true;
        },
        items() {
            if( this.localEvent == null )
                return [];

            let ix = this.localEvent.reservations.findIndex(i => i.customerId == this.customer.id);
            if( ix != -1 )
                return this.localEvent.reservations[ix].items;

            return [];
        },
    },
    mounted() {
        this.onResize();
        this.loadEvent();
    },
    methods: {
        ...mapActions({
            setReservation  : 'setReservation'
        }),
        async loadEvent() {
            if( this.event.foreignKey.length == 0 || this.event.coordinatorPhone.length == 0 )
                return;

            try {
                let api = new API();

                this.loading = true;
                let res = await api.post('/customer-event', {
                    eventNum: this.event.foreignKey,
                    phoneNum: this.event.coordinatorPhone,
                    customerId: this.customer.id
                });
                this.loading = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.error ? 'Error' : 'Warning', res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.localEvent = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage('Error', error, 'error');
            }
        },
        nextStep() {
            if( this.reservationFound !== true )
                return;

            try {
                let ix = this.localEvent.reservations.findIndex(i => i.customerId == this.customer.id);

                let res = this.localEvent.reservations[ix];
                if( !_st.isNUE( res.orderId ) ) {
                    this.setReservation( null );
                    this.mxShowMessage('Warning', 'An order already exist for this reservation.', 'warning');
                    return;
                }

                this.setReservation( res );
                this.$router.push('/height-weight');
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage('Error', error, 'error');
            }

        },
        toMoney(n) {
            return _st.toMoney(n);
        },
        onResize() {
            this.isMobile = window.innerWidth < 600;
        },
    },
    directives: { mask }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.fit {
  background-color: #ececec;
}

.notice {
    padding: 20px;
    background-color: #e2e2e2;
    border-radius: 8px;
}
</style>